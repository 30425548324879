import React from "react";
import ReactMarkdown from "react-markdown";
import BasePage from "../components/base-page";
import Layout from "../components/layout";

export default function NotFoundPage(props) {
  return (
    <BasePage {...props}>
      <Layout>
        <ReactMarkdown source={props.pageContext.content} />
      </Layout>
    </BasePage>
  )
}